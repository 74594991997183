import React, { Component } from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';


export default class PhoneInput extends Component
{
	constructor(props)
	{
		super(props);

		this.state = { phone: props.phone || "" };
	}

	isValidPhone()
	{
		return this.state.phone?.length === 11;
	}
	isEnabled = () =>
	{
		return this.props.enabled !== false;
	};

	onChangePhone = (value/*, mask*/) =>
	{
		if(value !== this.state.phone)
		{
			this.setState({ phone: value });
			if(this.props.onDone != null)
				if(this.props.phone && this.props.phone !== value)
					this.props.onDone("-");
		}
	};
	onSubmitPhone = (event) =>
	{
		event.preventDefault();
		if(this.props.onDone && this.isValidPhone())
			this.props.onDone(this.state.phone);
	};


	render()
	{
		return (
			<form className={ this.props.className } onSubmit={ this.onSubmitPhone }>
				<label htmlFor="phoneInput" className="form-label">Укажите Вaш номер телефона</label>
				<div className='d-flex'>
					<IMaskInput
						id="phoneInput"
						mask={ "+{7} (000) 000-00-00" }
						value={ this.state.phone }
						unmask={ true } // true|false|'typed'
						inputRef={ el => this.input = el }  // access to nested input
						// DO NOT USE onChange TO HANDLE CHANGES! USE onAccept INSTEAD
						onAccept=
							{
								// depending on prop above first argument is
								// `value` if `unmask=false`,
								// `unmaskedValue` if `unmask=true`,
								// `typedValue` if `unmask='typed'`
								//(value, mask) => console.log(value)
								this.onChangePhone
							}
						// ...and more mask props in a guide
						// input props also available
						placeholder='+7(777)777-77-77'
						disabled={ this.isEnabled() === false }
						className="form-control col" />
					<button
						type='submit'
						disabled={ (this.isValidPhone() && this.isEnabled() ) !== true }
						className='btn btn-primary col-auto ms-2' >
						Принять
					</button>
				</div>
			</form>
		);
	}
}

PhoneInput.propTypes =
{
	phone: PropTypes.string,
	enabled: PropTypes.bool,
	onDone: PropTypes.func,
	className: PropTypes.string
};