import React from "react";
import { Modal } from "bootstrap";
import PropTypes from 'prop-types';

export default class Dialog extends React.Component
{
	constructor(props)
	{
		super(props);
		this.domRef = React.createRef();
	}

	componentDidMount()
	{
		const dom = this.domRef.current;
		dom.addEventListener('hidden.bs.modal', this.props.onHideModal);
		const modal = Modal.getOrCreateInstance(dom);
		modal.show();
		//this.getDOMNode().on('hidden.bs.modal', this.props.onHideModal);
		//this.getDOMNode().modal('show');
	}
	componentWillUnmount()
	{
		const dom = this.domRef.current;
		dom.removeEventListener('hidden.bs.modal', this.props.onHideModal);
	}

	render()
	{
		return (
			<div ref={ this.domRef } className="modal fade">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<span className='state-image state-image-alert' />
							<h5 className="modal-title">{ this.props.title || "Сообщение" }</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<p>{ this.props.message || "" }</p>
						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-primary btn-default" data-bs-dismiss="modal">Закрыть</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Dialog.propTypes =
{
	title: PropTypes.string,
	message: PropTypes.string,
	onHideModal : PropTypes.func
};