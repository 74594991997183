import React, { Component } from 'react';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';

export default class PaySend extends Component
{
	constructor(props)
	{
		super(props);

		this.state =
		{
			fname: "",
			iname: "",
			oname: "",
			isFemale: true,
			email: "",
			sum: 0,
			reason: ""
		};

	}


	isEnabled = () =>
	{
		return this.props.enabled !== false;
	};
	isRegistered = () =>
	{
		return this.props.registered === true;
	};

	onChange = (event) =>
	{
		const target = event.target;
		let name = target.name;
		let value = target.value;
		if(name !== "reason")
			value = value.trim();
		if(name === "sex")
		{
			name = 'isFemale';
			value = value === 'female';
		}
		this.setState({ [name]: value });
	};
	onChangeSum = (value/*, mask*/) =>
	{
		this.setState({ sum: value });
	};

	onSubmit = (event) =>
	{
		event.preventDefault();

		if(this.isRegistered() === false)
		{
			if(this.state.fname.length === 0)
				return alert("Укажите фамилию!");
			if(this.state.iname.length === 0)
				return alert("Укажите имя!");
			if(this.state.oname.length === 0)
				return alert("Укажите отчество!");
		}
		if(this.state.sum <= 0)
			return alert("Сумма должна быть больше нуля!");

		if(this.props.onDone)
			this.props.onDone(this.state);
	};

	render()
	{
		return (
			<div className={ this.props.className }>

				{ this.isRegistered() &&
					<p className='mb-3'>
						<div className='state-image state-image-ok' />
						<strong>Указанный номер зарегистрирован. </strong><br />
					</p>
				}
				{ this.isRegistered() === false &&
					<p className='mb-3'>
						<div className='state-image state-image-alert' />
						<strong>Указанный номер не зарегистрирован!</strong><br />
					</p>
				}

				<form onSubmit={ this.onSubmit } >

					{ this.isRegistered() === false &&
						<fieldset disabled={ !this.isEnabled() }>
							<div className="form-floating mb-1">
								<input type="text"
									id="fName"
									name="fname"
									className="form-control"
									placeholder="Фамилия"
									required
									value={ this.state.fname }
									onChange={ this.onChange }
								/>
								<label htmlFor="fName">Фамилия</label>
							</div>

							<div className="form-floating mb-1">
								<input type="text"
									id="iName"
									name="iname"
									className="form-control"
									placeholder="Имя"
									required
									value={ this.state.iname }
									onChange={ this.onChange }
								/>
								<label htmlFor="iName">Имя</label>
							</div>

							<div className="form-floating mb-1">
								<input type="text"
									id="oName"
									name="oname"
									className="form-control"
									placeholder="Отчество"
									required
									value={ this.state.oname }
									onChange={ this.onChange }
								/>
								<label htmlFor="oName">Отчество</label>
							</div>

							<div className='form-control p-3 mb-1'>
								<span className='me-3'>Пол</span>
								<div className="form-check form-check-inline">
									<input className="form-check-input"
										type="radio"
										name="sex"
										value="male"
										id="male"
										checked={ !this.state.isFemale }
										onChange={ this.onChange }
									/>
									<label className="form-check-label" htmlFor="male"> Мужской </label>
								</div>
								<div className="form-check form-check-inline">
									<input className="form-check-input"
										type="radio"
										name="sex"
										value="female"
										id="female"
										checked={ this.state.isFemale }
										onChange={ this.onChange }
									/>
									<label className="form-check-label" htmlFor="female"> Женский </label>
								</div>
							</div>

							<div className="form-floating mb-3">
								<input type="email"
									id="iEmail"
									name="email"
									className="form-control"
									placeholder="name@example.com"
									value={ this.state.email }
									onChange={ this.onChange }
								/>
								<label htmlFor="iEmail">Email</label>
							</div>
						</fieldset>
					}

					<fieldset disabled={ !this.isEnabled() }>

						<div className="form-floating mb-1">
							<IMaskInput
								id="sum"
								name="sum"
								mask={ Number }
								thousandsSeparator="&nbsp;"
								className="form-control"
								required
								value={ this.state.sum || null }
								placeholder='Сумма платежа, ₽'
								// DO NOT USE onChange TO HANDLE CHANGES! USE onAccept INSTEAD
								unmask={ true } // true|false|'typed'
								onAccept=
									{
										// depending on prop above first argument is
										// `value` if `unmask=false`,
										// `unmaskedValue` if `unmask=true`,
										// `typedValue` if `unmask='typed'`
										//(value, mask) => console.log(value)
										this.onChangeSum
									}
							/>
							<label htmlFor="sum">Сумма платежа, ₽</label>
						</div>

						<div className="form-floating mb-2">
							<input type="text"
								id="reason"
								name="reason"
								className="form-control"
								value={ this.state.reason }
								onChange={ this.onChange }
								placeholder='Назначение платежа'
								required />
							<label htmlFor="reason">Назначение платежа</label>
						</div>

						<div className='d-flex justify-content-end'>
							<button className='btn btn-primary'
								type='submit'>
								Оплатить
							</button>
						</div>
					</fieldset>
				</form>
			</div>
		);
	}
}

PaySend.propTypes =
{
	enabled: PropTypes.bool,
	registered: PropTypes.bool,
	onDone: PropTypes.func,
	className: PropTypes.string
};