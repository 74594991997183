import React from "react";
import PaySend from "./PaySend";
import PhoneInput from "./PhoneInput";
import Modal from "./Modal";

export default class App extends React.Component
{
	static errText = "Ошибка выполнения запроса!\nПопробуйте повторить позднее.";

	constructor(props)
	{
		super(props);

		const urlParams = new URLSearchParams(window.location.search);
		const phone = urlParams.get("phone");
		const returnUrl = window.returnUrl;
		let stage = urlParams.get("result");
		if(stage === "ok")
			stage = 2;
		else if(stage === "fail")
			stage = 3;
		else
			stage = 0;

		this.state =
		{
			phone: phone || "",
			uid: null,

			stage: stage,
			loading: false,
			error: null,
			returnUrl: returnUrl
		};
	}

	isRegistered = () =>
	{
		return !!this.state.uid;
	};

	onPhoneEnter = (value) =>
	{
		if(value === "-")
		{
			if(this.state.phone)
				this.setState({ phone: null, stage: 0 });
		}
		else if(value != null)
			this.setState({ phone: value, loading: true }, async () =>
			{
				try
				{
					let response = await fetch(`checkPhone?phone=${this.state.phone}`);
					if(response.ok === false)
						throw new Error(response.status);
					let result = await response.json();
					this.setState({ uid: result?.oid, stage: 1, loading: false });
				}
				catch(err)
				{
					this.setState({ loading: false }, () => this.showErrorBox());
				}

				// const p = new Promise((resolve, reject) =>
				// {
				// 	console.log("send check request...");
				// 	setTimeout(() => resolve('5c35c1d1-f94e-4cc2-9641-5d3226a56bdd'), 500);
				// });
				// p.then(oid =>
				// {
				// 	this.setState({ uid: oid, stage: 1, loading: false });
				// }).catch(err =>
				// {
				// 	this.setState({ loading: false }, () => this.showErrorBox());
				// });

			});
	};
	onPayEnter = (value) =>
	{
		this.setState({ loading: true }, async () =>
		{
			try
			{
				let data = this.isRegistered() ? { sum: value.sum, reason: value.reason } : value;
				data.uid = this.state.uid || "";
				data.phone = this.state.phone;
				data.returnUrl = this.state.returnUrl;

				let response = await fetch('regPayment',
					{
						method: 'POST',
						headers: { 'Content-Type': 'application/json;charset=utf-8' },
						body: JSON.stringify(data)
					});
				if(response.ok === false)
					throw new Error(response.status);
				let result = await response.text();
				if(result)
					window.location.assign(result);
				else
					this.setState({ loading: false }, () => this.showErrorBox());
			}
			catch(err)
			{
				this.setState({ loading: false }, () => this.showErrorBox());
			}
		});
	};

	showErrorBox = (errText) =>
	{
		if(errText && typeof errText !== "string")
			errText = null;
		this.setState({ error: errText || App.errText });
	};
	hideErrorBox = () =>
	{
		this.setState({ error: null });
	};

	render()
	{
		return (
			<>
				<div className="header p bg-primary text-white">
					<h1><img src="static/images/logo.png" alt="logo" /></h1>
					<h1 className="text-center flex-fill">Оплата для<br />ЦР&nbsp;«Открытый&nbsp;путь»</h1>
				</div>

				<div className="ps-2 pe-2">
					<PhoneInput
						phone={ this.state.phone }
						enabled={ !this.state.loading }
						onDone={ this.onPhoneEnter }
						className="mb-3"
					/>

					{ this.state.stage === 1 &&
						<PaySend
							enabled={ !this.state.loading }
							registered={ this.isRegistered() }
							onDone={ this.onPayEnter }
							className="mb-3">
						</PaySend>
					}

					{ this.state.stage === 2 &&
						<p className='mb-3'>
							<div className='state-image state-image-ok' />
							<strong>Олата успешно завершена!</strong><br />
							<br />
							{ this.state.returnUrl &&
								<a href={ this.state.returnUrl }> Вернуться на основной сайт </a>
							}
						</p>
					}

					{ this.state.stage === 3 &&
						<p className='mb-3'>
							<div className='state-image state-image-alert' />
							<strong>Олата <span className="text-danger">НЕ</span> была завершена из-за ошибки!</strong><br />
							<br />
							<p>Попробуйте повторить оплату немного позднее.<br />
								Если ошибка повторится, пожалуйста свяжитесь с сотрудником нашей компании.
							</p>
							<br />
							{ this.state.returnUrl &&
								<a href={ this.state.returnUrl }> Вернуться на основной сайт </a>
							}
						</p>
					}

					{ this.state.loading &&
						<div className="d-flex justify-content-center mt-5">
							<div className="spinner-border text-primary"
								style={ { width: "3rem", height: "3rem" } }
								role="status">
								<span className="sr-only"></span>
							</div>
						</div>
					}

					{ this.state.error &&
						<Modal
							title="Ошибка связи с сервером"
							message={ this.state.error }
							onHideModal={ this.hideErrorBox } />
					}

				</div>

			</>
		);
	}
}



